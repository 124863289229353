import React, { useState, useEffect } from "react";
import "../App.css";
import "../index";
import { ArrayDetails as LastArraysDetails } from "./Galery";
import GaleryModal from "./Galerymodal";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../static/logo.png";
import mailIcon from "../static/mail-icon.png";
import instaIcon from "../static/instagram-icon.png";
import fbIcon from "../static/facebook-icon.png";

//images
import peintre from "../static/peintre-accueil.JPG";
// App

export function Header() {
  return (
    <div className="app-header h-full col-12 grid justify-content-center">
      <div className="col-12">
        <img className="col-11 md:col-8 lg:col-6" src={logo} alt="logo"></img>
        <Menu></Menu>
      </div>
    </div>
  );
}
export function Footer() {
  return (
    <div className="app-footer grid flex justify-content-center font-light lg:h-15rem py-6 md:py-0">
      <div className="flex justify-content-center grid col-3 lg:col-10 lg:justify-content-around">
        <a
          href="mailto:jean.marc.debergue@gmail.com"
          className="contact-email flex flex-inline align-items-center"
        >
          <img src={mailIcon} alt="e-mail" className="w-5rem"></img>
          <p>jean.marc.debergue@gmail.com</p>
        </a>
        <a
          href="https://www.instagram.com/jim.debergue/"
          target="_blank"
          rel="noopener noreferrer"
          className="insta flex flex-inline align-items-center"
        >
          <img src={instaIcon} alt="instagram" className="w-3rem"></img>
          <p className="ml-2">jim.debergue</p>
        </a>
        <a
          href="https://www.facebook.com/JMDart31/"
          target="_blank"
          rel="noopener noreferrer"
          className="facebook flex flex-inline align-items-center"
        >
          <img src={fbIcon} alt="facebook" className="w-3rem"></img>
          <p className="ml-2">jim.debergue</p>
        </a>
      </div>
    </div>
  );
}
export function Menu() {
  const [ipAddress, setIpAddress] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        setIpAddress(ip);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de l'adresse IP :",
          error
        );
      });
  }, [ipAddress]);

  const handleOpenMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="menu grid col-12 justify-content-center ">
      <div
        onClick={(e) => handleOpenMenu(e)}
        className="flex flex-column w-2rem md:hidden mt-5"
      >
        <button className="mb-1"></button>
        <button className="mb-1"></button>
        <button className="mb-1"></button>
      </div>
      <div>
        {isMenuOpen && (
          <div className="opened-menu flex flex-column md:hidden h-20rem justify-content-between pl-8 pr-8 pt-3 pb-3 mt-5 border-round-2xl ">
            {ipAddress === process.env.REACT_APP_ALLOWED_IP && (
              <Link to="/admin" >
                Admin
              </Link>
            )}

            <Link to="/Galery">
              Galerie
            </Link>

            <Link to="/Artiste">
              L'artiste
            </Link>

            <Link to="/Contact">
              Contact
            </Link>
          </div>
        )}
      </div>
      <div className=" hidden md:inline md:col-7 lg:col-5">
        <hr className=""></hr>
        <div className="flex justify-content-between">
        {ipAddress === process.env.REACT_APP_ALLOWED_IP && (
          <Link to="/admin">Admin</Link>
        )}
        <Link to="/Galery">Galerie</Link>
        <Link to="/Artiste">L'artiste</Link>
        <Link to="/Contact">Contact</Link>
        </div>
      </div>
    </div>
  );
}

export function ArtistMain() {
  return (
    <div className="artist-main grid flex col-12 text-white justify-content-center lg:py-8">
      <div className="main-text col-12 lg:col-6 grid lg:pt-8">
        <div className="col-12">
          <p className="text-5xl md:text-4xl">Le peintre</p>
          <p className="" >
            Jim Debergue, de son vrai nom Jean-Marc Debergue, est un artiste
            toulousain.
          </p>
        </div>
        <div className="col-12">
          <p className="text-5xl md:text-4xl ">Son art</p>
          <p >
            Il crée ses œuvres à base de couches successives de peintures, de
            pigments, de liants et d’inclusions et développe ainsi la
            transparence et les volumes.
          </p>
        </div>
      </div>
      <div className="col-12 lg:col-6 lg:pt-8">
      <img className="w-full" src={peintre} alt="peintre"></img>
    </div>
    </div>
  );
}

export function LastArrays() {
  const [selectedArray, setSelectedArray] = useState([]);
  const [lastArrays, setLastArrays] = useState([]);
  const [mainPic, setMainPic] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_ARRAYS}`,
          {
            params: {
              $sort: {
                created_at: -1,
              },
              $limit: 3,
              $select: [
                "id",
                "name",
                "dimension",
                "imagecompressed",
                "image2compressed",
              ],
            },
            mode: "cors",
          }
        );
        const data = response.data;
        setLastArrays(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données : ", error);
      }
    };

    fetchData();
  }, []);

  // Opening modal for Array-details

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    fetchSelectedArray();
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArray([]);
    setMainPic("");
    setModalOpen(false);
  };

  // Set selected array for details

  const fetchSelectedArray = async (arrayId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_ARRAYS}/${arrayId}`,
        {
          params: {
            $select: [
              "id",
              "name",
              "description",
              "dimension",
              "year",
              "image",
              "image2",
              "image3",
              "image4",
            ],
          },
          mode: "cors",
        }
      );
      const arrayData = response.data;
      setSelectedArray(arrayData);
      setMainPic(arrayData.image);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données du tableau sélectionné : ",
        error
      );
    }
  };

  // Select array function
  const handleClick = (array) => {
    fetchSelectedArray(array.id);
  };

  return (
    <div className="last-work grid col-12 text-center justify-content-center ">
      <p className="text-black text-5xl lg:text-4xl col-12">Les dernieres oeuvres</p>

      <div className="col-12 grid justify-content-center">
        {lastArrays.map((array) => (
          <div className="col-12 md:col-4 py-8" key={array.id}>
            <div
              className="fondu relative cursor-pointer"
              value={array}
              onClick={() => {
                handleClick(array);
                openModal();
              }}
            >
              <img
                className="galerie-images-bas border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.image2compressed}`}
                alt={array.name}
              ></img>
              <img
                className="galerie-images-haut border-round-xl absolute left-0 w-full"
                src={`data:image/webp;base64,${array.imagecompressed}`}
                alt={array.name}
              ></img>
            </div>

            <div className="g-array-text w-full">
              <p className="m-0 text-4xl md:text-2xl lg:text-xl ">{array.name}</p>
              <p className="text-lg">{array.dimension}</p>
              <hr></hr>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && selectedArray.id && (
        <GaleryModal isOpen={isModalOpen} onClose={closeModal}>
          <LastArraysDetails
            array={selectedArray}
            mainPic={mainPic}
          ></LastArraysDetails>
        </GaleryModal>
      )}
    </div>
  );
}

export function App() {
  return (
    <div className="App text-center -ml-2 -mt-2 w-full absolute h-full bg-no-repeat pb-3 col-12 text-xl md:text-lg ">
      <Header></Header>
      <ArtistMain></ArtistMain>
      <LastArrays></LastArrays>
      <Footer></Footer>
    </div>
  );
}

export default App;
